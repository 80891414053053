<template>
    <div>
        <!-- <span v-if="checkPermission('all_access')"> -->
        <b-sidebar id="sidebar-editinvoice" sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header right>
            <template #default="{ hide }">
                <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                    <h3 class="mb-0">
                        Edit Invoice :
                    </h3>
                    <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
                </div>

                <validation-observer ref="editUserinvoice">
                    <b-form @submit="onSubmit" @reset="onReset" class="m-2" id="FORMID">
                        <b-form-group id="supplier" label="Supplier:" label-for="input-supplier">
                            <b-form-select v-model="selected_supplier" :options="supplier_options"
                                class="demo-inline-spacing"></b-form-select>
                        </b-form-group>
                        <b-form-group id="Amount" label="Amount:" label-for="Amount">
                            <validation-provider #default="{ errors }" name="Amount" rules="required">
                                <b-form-input id="Amount" v-model="form.Amount" placeholder="Amount" type="number">
                                </b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                        <b-form-group id="Tax" label="Tax:" label-for="Tax">
                            <validation-provider #default="{ errors }" name="Tax" rules="required">
                                <b-form-input id="Tax" v-model="form.Tax" placeholder="Tax" @input="formatTaxInput">
                                </b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                        <b-form-group id="pdf" label="pdf:" label-for="pdf">
                            <b-button variant="primary" @click="$refs.refInputEl.click()">
                                <input ref="refInputEl" type="file" accept="application/pdf" class="d-none" multiple
                                    @input="handleFileChange" />
                                <span class="d-none d-sm-inline">Upload PDF</span>
                                <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
                            </b-button>
                            <div v-if="form.pdf">
                                <embed :src="form.pdf" type="application/pdf" width="100%" height="600px" />
                            </div>
                        </b-form-group>
                    </b-form>
                </validation-observer>
            </template>

            <template #footer>
                <div class="flex mb-2 ml-2">
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" form="FORMID" variant="success" class="mr-2"
                        type="submit" @click.prevent="onSubmit">
                        Update
                    </b-button>

                    <b-button type="reset" variant="danger" class="mr-2" form="FORMID">
                        Reset
                    </b-button>

                    <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-danger" form="FORMID"
                        @click="closeSidebar">
                        Cancel
                    </b-button>
                </div>
            </template>
        </b-sidebar>
        <!-- </span> -->
    </div>
</template>

<script>
import User from '../../../Helpers/User'
import {
    BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BButton, BFormRadioGroup, BFormCheckboxGroup, BFormSelect,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
    required, id, supplier,
    amount,
    pdf,
} from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Datepicker from 'vue-datepicker';
import { BFormDatepicker } from 'bootstrap-vue'

export default {
    components: {
        BForm, BFormGroup, BFormInput, BFormTextarea, BButton, BSidebar, BFormRadioGroup,
        ValidationProvider, ValidationObserver, ToastificationContent, BFormDatepicker, BFormCheckboxGroup, BFormSelect,
    },
    directives: {
        Ripple,
    },
    props: {
        editinvoice: {
            type: Object,
            required: true,
        }
    },
    watch: {
        editinvoice() {
            this.form.id = this.editinvoice.id;
            this.form.supplier = this.editinvoice.supplier;
            this.form.amount = this.editinvoice.amount;
            this.form.pdf = this.editinvoice.pdf;
        }
    },
    data() {
        return {
            required, id, supplier,
            amount,
            pdf,
            form: {
                id: '',
                supplier: '',
                amount: '',
                pdf: '',
            },
            selected_supplier: [],
            supplier_options: [
            ],
        }
    },

    mounted() {
    },
    methods: {
        handleFileChange(event) {
            const file = event.target.files[0];
            this.readFile(file);
        },

        readFile(file) {
            const reader = new FileReader();

            reader.onload = () => {
                const pdfData = reader.result;
                this.form.pdf = pdfData;
            };

            reader.readAsDataURL(file);
        },
        created() {
            this.getAllsuppliers();
        },
        checkPermission(permission) {

            return User.checkPermission(permission)
        },

        onReset(event) {
            event.preventDefault()
            this.clearForm()
        },
        clearForm() {
            this.form.supplier = '',
                this.form.amount = '',
                this.form.pdf = ''
        },
        getAllsuppliers() {
            // this.loader = true;
            axios
                .get(process.env.VUE_APP_API + "/supplier")
                .then((res) => {
                    this.loader = false;
                    this.supplier_options = res["data"].map(
                        (option) => option.name)
                })
                .catch((error) => {
                    this.loader = false;
                    console.log(error);
                });
        },
        formatTaxInput() {
            if (this.form.Tax) {
                const numericValue = this.form.Tax.replace(/\D/g, '');
                this.form.Tax = numericValue + "%";
            }
        },
        onSubmit(event) {
            this.$refs.editUserinvoice.validate().then(success => {
                if (success) {
                    this.loader = true;
                    axios
                        .put(process.env.VUE_APP_API + '/invoice/invoice', {
                            'id': this.form.id,
                            'supplier': this.selected_supplier,
                            'amount': this.form.amount,
                            'pdf': this.form.pdf,
                        }
                            , { headers: { Authorization: `Bearer ${User.token()}` } })
                        .then((res) => {
                            if (res.data.success) {
                                this.getAllexpenses();
                                this.loader = false;
                                this.$toast(
                                    {
                                        component: ToastificationContent,
                                        props: {
                                            title: 'invoice Updated',
                                            icon: 'AlertOctagonIcon',
                                            text: '',
                                            variant: 'success',
                                        },
                                    },
                                    {
                                        position: 'top-right',
                                    }
                                );
                            } else {
                                this.loader = false;
                                this.$toast(
                                    {
                                        component: ToastificationContent,
                                        props: {
                                            title: 'Failed',
                                            icon: 'AlertOctagonIcon',
                                            text: '',
                                            variant: 'danger',
                                        },
                                    },
                                    {
                                        position: 'top-left',
                                    }
                                );
                            }
                        })
                        .catch((error) => {
                            this.loader = false;
                            console.log(error);
                        });
                }
            });
        },
        closeSidebar() {
            this.$root.$emit('bv::toggle::collapse', 'sidebar-editinvoice')
            this.clearForm()
        },
    },
    computed: {
    }

}
</script>
