<template>
    <div class="position-relative">
        <b-row>
            <b-col md="6">
                <!-- <span v-if="checkPermission('all_access')"> -->
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary"
                    v-b-toggle.sidebar-AddNewIncomingInvoice>
                    Add New Invoice</b-button>
                <!-- </span> -->
            </b-col>
            <b-col md="6">
                <b-row>
                    <b-col md="8">
                        <b-form-group   label-cols-sm="2" label-align-sm="right" label-size="md"
                            label-for="filterInput">
                            <b-input-group size="sm">
                                <b-form-input id="filterInput" v-model="filter" type="search"
                                    placeholder="Type to Search" />
                                <b-input-group-append>
                                    <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col md="4">
                        <b-form-group   label-cols-sm="4" label-align-sm="right" label-size="sm"
                            label-for="perPageInput">
                            <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions"
                                @change="savePerPage(perPage)" />
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-table
  v-if="invoice.length > 0"
  :items="invoice"
  :fields="fields"
  responsive="sm"
  :sticky-header="false"
  striped
  small
  id="invoice-table"
  :per-page="perPage"
  :current-page="currentPage"
  :filter="filter"
  :filter-included-fields="filterOn"
  @filtered="onFiltered"
>
            <template #cell(invoice_user)="row">
                <span v-if="row.item.invoice_user">
                    {{ row.item.invoice_user.name }}
                </span>
                <span v-else>
                    This User Does Not Exist
                </span>
                <!-- {{ row.item.invoice_user ? row.item.invoice_user.name : 'N/A'}} -->
            </template>
            <template #cell(status)="row">
                <b-badge pill :variant="`light-${resolveInvoiceStatusVariant(row.item.paid)}`" class="text-capitalize">
                    {{ resolveInvoiceStatus(row.item.paid) }}
                </b-badge>
            </template>
            <template #cell(pdf)="row">
                <b-button variant="primary" @click="downloadPDF(row.item.pdf)">Download PDF</b-button>
            </template>
            <!-- <div v-if="row.item.pdf" @click="downloadPDF(row.item.pdf)">Download XXX</div> -->

            <template #cell(date_created)="row">
                <span>{{ row.item.created_at | formatDate }}</span>
            </template>
            <template #cell(action)="row">
                <!-- <span v-if="checkPermission('all_access')"> -->
                <!-- <span v-if="row.item.active">
                        <feather-icon icon="SlashIcon" size="16" class="text-body align-middle mr-25 cursor-pointer"
                            v-b-tooltip.hover.v-danger title="Unpaid"
                            @click="showConfirmation(row.item.id); paid_unpaidInvoice(row.item.id, false)" />
                    </span> -->

                <span v-if="!row.item.active">
                    <feather-icon icon="UnlockIcon" size="16" class="text-body align-middle mr-25 cursor-pointer"
                        v-b-tooltip.hover.v-warning title="Paid" @click="showConfirmation(row.item.id, row.item)" />
                </span>
                <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                    <template v-slot:button-content>
                        <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
                    </template>
                    <b-dropdown-item v-b-toggle.sidebar-editinvoice @click="sendinvoiceEditProps(row.item)">
                        <feather-icon icon="Edit2Icon" class="mr-50" />
                        <span>Edit</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="deleteinvoice(row.item.id)">
                        <feather-icon icon="TrashIcon" class="mr-50" />
                        <span>Delete</span>
                    </b-dropdown-item>
                </b-dropdown>
                <!-- </span> -->
            </template>
        </b-table>
        <div v-else class="text-center mt-4">{{ emptyInvoiceMessage }}</div>
        <b-pagination :total-rows="rows" :per-page="perPage" v-model="currentPage" first-number last-number
            class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
            <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
        </b-pagination>
        <add-new-incoming-invoice @refresh="getAllinvoice" />
        <edit-invoice :editinvoice="editinvoice" @refresh="getAllinvoice" />
        <b-overlay :show="loader" no-wrap spinner-variant="primary"></b-overlay>
    </div>
</template>
<script>
import {
    BTable,
    BButton,
    VBToggle,
    BOverlay,
    BRow,
    BCol,
    BPagination,
    BFormGroup,
    BFormSelect,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    BBadge,
    VBTooltip,
    sweetalert,
    // swal
} from "bootstrap-vue";
import axios from "axios";
import User from "../../../Helpers/User";
import moment from "moment";
import Swal from 'sweetalert2';
import Ripple from "vue-ripple-directive";
// import { BPagination } from "bootstrap-vue";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AddNewIncomingInvoice from "./AddNewIncomingInvoice.vue";
import EditInvoice from "./EditInvoice.vue";
export default {
    components: {
        BTable,
        BButton,
        BRow,
        BCol,
        BPagination,
        BOverlay,
        BFormGroup,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BFormSelect,
        BDropdown,
        BDropdownItem,
        BBadge,
        ToastificationContent,
        AddNewIncomingInvoice,
        EditInvoice,
        // swal,
        sweetalert
    },
    directives: {
        Ripple,
        "b-toggle": VBToggle,
        'b-tooltip': VBTooltip,
    },
    data() {
        return {
            perPage: "25",
            pageOptions: [5, 10, 25, 50],
            currentPage: 1,
            totalRows: 0,
            emptyInvoiceMessage: "No Data Found.",
            filter: null,
            filterOn: [],
            fields: ["supplier", "amount", { key: "pdf", label: "pdf" },
                { key: "invoice_user", label: "Created By" }, "tax",
                "status", "date_created",
                { key: "action", label: "Action", tdClass: 'actionClass' }],
            invoice: [],
            loader: false,
            supplierData: {},
            editinvoice: {}
        };
    },
    mounted() {
    },
    computed: {
        rows() {
            return this.invoice.length;
        }
    },
    created() {
        this.getAllinvoice();
    },
    methods: {
        // showConfirmation(invoiceId) {
        //     Swal.fire({
        //         title: 'Confirmation',
        //         text: 'Are you sure you want to mark this invoice as paid?',
        //         icon: 'warning',
        //         showCancelButton: true,
        //         confirmButtonText: 'Yes, mark it as paid!',

        //         cancelButtonText: 'No, cancel!',
        //         reverseButtons: true,
        //     }).then((result) => {
        //         if (result.isConfirmed) {
        //             this.paid_unpaidInvoice(invoiceId, true);
        //         } else if (result.dismiss === Swal.DismissReason.cancel) {
        //             Swal.fire('Cancelled', 'The invoice is not marked as paid.', 'info');
        //         }
        //     });
        // },
        showConfirmation(invoiceId, action) {
            let invoice = action;
            Swal.fire({
                title: 'Invoice Information',
                html: `
                        <p><strong>Supplier:</strong> ${invoice.supplier}</p>
                        <p><strong>Amount:</strong> ${invoice.amount}</p>
                        <p><strong>Tax:</strong> ${invoice.tax}</p>
                        <p><strong>Status:</strong> ${this.resolveInvoiceStatus(invoice.paid)}</p>
                        <p><strong>Created By:</strong> ${invoice.invoice_user ? invoice.invoice_user.name : 'N/A'}</p>
                        <p><strong>Created At:</strong> ${this.$options.filters.formatDate(invoice.created_at)}</p>
                    `,
                icon: 'info',
                confirmButtonText1: 'OK',
                showCancelButton: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.paid_unpaidInvoice(invoiceId, true);
                }
            });
        },

        deleteItem() {
            // Code to delete the item
            Swal.fire('Deleted!', 'Your item has been deleted.', 'success');
        },
        downloadPDF(name) {
            console.log('name', name)
            axios
                .get(process.env.VUE_APP_API + "/download-pdf?name=" + name, {
                    responseType: "blob",
                    headers: {
                        Authorization: `Bearer ${User.token()}`,
                    },
                })
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", "invoice.pdf");
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        paid_unpaidInvoice(invoiceId, action) {

            if (invoiceId) {
                this.loader = true
                axios({
                    method: 'post',
                    url: process.env.VUE_APP_API + '/paid-unpaid-invoice',
                    data: {
                        'invoiceId': invoiceId,
                        'action': action
                    },
                    headers: {
                        Authorization: `Bearer ${User.token()}`,
                    },
                })
                    .then((res) => {
                        if (res.data.success) {
                            this.getAllinvoice()
                            this.loader = false
                        }
                        else {
                            this.loader = false
                            this.$toast
                                ({
                                    component: ToastificationContent,
                                    props: {
                                        title: 'Failed',
                                        icon: 'AlertOctagonIcon',
                                        text: '',
                                        variant: 'danger',
                                    },
                                },
                                    {
                                        position: 'top-left'
                                    })
                        }
                    })
                    .catch((error) => {
                        this.loader = false
                        console.log(error);
                    });
            }

        },
        resolveInvoiceStatus(status) {
            if (status == true) return 'Paid'
            if (status == false) return 'Unpaid'
        },
        resolveInvoiceStatusVariant(status) {
            if (status == true) return 'success'
            if (status == false) return 'secondary'
            return 'primary'
        },
        checkPermission(permission) {
            return User.checkPermission(permission)
        },
        sendinvoiceEditProps(param) {
            this.editinvoice = param
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        getAllinvoice() {
            this.loader = true;
            axios
                .get(process.env.VUE_APP_API + "/invoice", { headers: { Authorization: `Bearer ${User.token()}` } })
                .then((res) => {
                    this.loader = false;
                    this.invoice = res["data"];
                })
                .catch((error) => {
                    this.loader = false;
                    console.log(error);
                });
        },
    },
    deleteinvoice(invoiceId) {
        if (invoiceId) {
            this.loader = true;
            axios({
                method: 'delete',
                url: process.env.VUE_APP_API + '/invoice/invoice',
                data: {
                    'invoiceId': invoiceId,
                },
                headers: { Authorization: `Bearer ${User.token()}` }
            })
                .then((res) => {
                    if (res.data.success) {
                        this.getAllinvoice();
                        this.loader = false;
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Invoice Deleted',
                                icon: 'AlertOctagonIcon',
                                text: '',
                                variant: 'danger',
                            },
                        }, {
                            position: 'top-right',
                        });
                    } else {
                        this.loader = false;
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Failed',
                                icon: 'AlertOctagonIcon',
                                text: '',
                                variant: 'danger',
                            },
                        }, {
                            position: 'top-left',
                        });
                    }
                })
                .catch((error) => {
                    this.loader = false;
                    console.log(error);
                });
        }
    },
    filters: {
        formatDate(value) {
            if (value) {
                return moment(String(value)).format("YYYY-MM-DD , hh:mm:ss");
            }
        },
    },
}

</script>
      
<style> .actionClass {
     max-width: 300px;
 }
</style>